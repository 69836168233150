<template>
  <v-card>
    <v-card-actions>
      Progress notes
      <v-spacer></v-spacer>

      <v-select
        v-model="deleted"
        :items="[
          { text: 'Active notes', value: false },
          { text: 'Deleted notes', value: true },
        ]"
        @change="toggleDeleted"
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn color="primary" small @click="addNote">Add note</v-btn>

      <v-btn fab small v-if="deleted">
        <v-icon size="16">mdi-note-outline</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :items="notes"
        :dense="true"
        :items-per-page-options="[10, 20, 30, 40, 50]"
        :items-per-page="20"
        :headers="headers"
        class="elevation-1"
        v-if="!deleted"
      >
        <template slot="item.notes" slot-scope="props">
          <div style="max-width: 400px">
            <span
              v-if="
                props.item.lineItem !== undefined &&
                props.item.lineItem.isOld !== undefined &&
                props.item.lineItem.isOld
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon color="orange darken-2" size="16" v-on="on">mdi-alert</v-icon>
                </template>
                <span>Using outdated price guide </span>
              </v-tooltip>
            </span>
            {{ props.item.notes }}
          </div>
        </template>

        <template slot="item.duration" slot-scope="props">
          <div>{{ util.toFixed(props.item.duration, 2) }}</div>
        </template>

        <template slot="item.quantity" slot-scope="props">
          <div>{{ util.toFixed(props.item.quantity, 2) }}</div>
        </template>
        <template slot="item.price" slot-scope="props">
          <div>${{ util.toFixed(props.item.price, 2) }}</div>
        </template>
        <template slot="item.startDate" slot-scope="props">
          <div>{{ props.item.startDateString + " " + props.item.startTime }}</div>
        </template>
        <template slot="item.endDate" slot-scope="props">
          <div>{{ props.item.endDateString + " " + props.item.endTime }}</div>
        </template>
        <template slot="item.modifiedOn" slot-scope="props">
          <div>
            {{ moment(util.toDt(props.item.modifiedOn)).format("DD-MM-YYYY HH:mm") }}
          </div>
        </template>
        <template slot="item.actions" slot-scope="props">
          <div>
            <v-icon slot="activator" small class="mr-2" @click="editItem(props.item)"
              >mdi-pencil</v-icon
            >
            <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>

      <v-data-table
        :items="deletedNotes"
        :dense="true"
        :headers="deletedHeaders"
        class="elevation-1"
        v-if="deleted"
        :rows-per-page-items="[15, 30, 50, 100]"
        :pagination.sync="pagination"
      >
        <template slot="item.notes" slot-scope="props">
          <div style="max-width: 400px">{{ props.item.notes }}</div>
        </template>

        <template slot="item.duration" slot-scope="props">
          <div>{{ util.toFixed(props.item.duration, 2) }}</div>
        </template>

        <template slot="item.quantity" slot-scope="props">
          <div>{{ util.toFixed(props.item.quantity, 2) }}</div>
        </template>
        <template slot="item.price" slot-scope="props">
          <div>${{ util.toFixed(props.item.price, 2) }}</div>
        </template>
        <template slot="item.startDate" slot-scope="props">
          <div>{{ props.item.startDateString + " " + props.item.startTime }}</div>
        </template>
        <template slot="item.endDate" slot-scope="props">
          <div>{{ props.item.endDateString + " " + props.item.endTime }}</div>
        </template>
        <template slot="item.deletedOn" slot-scope="props">
          <div>
            {{
              props.item.deletedOn == undefined
                ? ""
                : moment(props.item.deletedOn.toDate()).format("YYYY-MM-DD hh:mm")
            }}
          </div>
        </template>
        <template slot="item.deletedBy" slot-scope="props">
          <div>
            {{
              props.item.deletedBy == undefined ? "" : props.item.deletedBy.displayName
            }}
          </div>
        </template>
        <template slot="item.actions" slot-scope="props">
          <div>
            <v-icon small @click="unDeleteItem(props.item)">mdi-undo-variant</v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

import * as notesService from "../../../services/progressNotesService";
import * as budgetItemService from "../../../services/budgetItemService";
import * as reportService1 from "../../../services/reportService1.js";
import moment from "moment";
Vue.use(VeeValidate);

export default {
  name: "notes-list",
  data: () => ({
    pagination: {
      rowsPerPage: 30,
    },
    dialog: false,
    vm: {},
    form: {},
    valid: true,
    checkbox: false,
    util: null,
    notes: [],
    budgetItems: [],
    deleted: false,
    isTravel: false,
    deletedNotes: [],
    caseId: "",
    goalId: "",
    participantName: "",
    participantFirstname: "",
    participantLastname: "",
    nDISNumber: "",
    headers: [
      { text: "Notes", value: "notes" },
      { text: "Duration", value: "duration", align: "end" },
      { text: "Quantity", value: "quantity", align: "end" },
      { text: "Price", value: "price", align: "end" },
      { text: "Contact Type", value: "contactType" },
      { text: "Start", value: "startDate" },
      { text: "End", value: "endDate" },
      { text: "Last modified", value: "modifiedOn" },

      { text: "", value: "actions" },
    ],
    deletedHeaders: [
      { text: "Notes", value: "notes" },
      { text: "Duration", value: "duration", align: "end" },
      { text: "Quantity", value: "quantity", align: "end" },
      { text: "Price", value: "price", align: "end" },
      { text: "Contact Type", value: "contactType" },
      { text: "Start", value: "startDate" },
      { text: "End", value: "endDate" },
      { text: "Deleted By", value: "deletedBy" },

      { text: "Deleted On", value: "deletedOn" },
      { text: "", value: "actions" },
    ],
  }),
  props: {
    msg: String,
  },
  mounted() {
    this.util = this.$utils;
    if (this.currentCase !== null) {
      this.participantName =
        this.currentCase.participantDetails.firstname +
        " " +
        this.currentCase.participantDetails.lastname;
      this.nDISNumber = this.currentCase.participantDetails.nDISNumber;

      this.participantFirstname = this.currentCase.participantDetails.firstname;
      this.participantLastname = this.currentCase.participantDetails.lastname;
    }

    if (this.currentGoal != null) {
      this.init();
    }
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal() {
      return this.$store.getters.currentGoal;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      notesService
        .list(this.currentOrganisation.id, this.currentCase.id, this.currentGoal.id)
        .then((result) => {
          let notes = [];
          result.docs.map((doc) => {
            const note = doc.data();
            note.id = doc.id;

            const validationResult = notesService.validateProgressNote(note);
            if (validationResult.valid) {
              note.errors = [];
            } else {
              note.errors = validationResult.errors;
            }
            notes.push(note);
          });

          console.log(notes);
          let totalSpent = 0;
          let budgetItemId = "";
          for (let i = 0; i < notes.length; i++) {
            if (notes[i].quantity == 0 || notes[i].quantity == undefined) {
              totalSpent += notes[i].price * notes[i].duration;
            }
            if (notes[i].duration == 0 || notes[i].duration == undefined) {
              totalSpent += notes[i].price * notes[i].quantity;
            }

            budgetItemId = notes[i].budgetItem.id;
          }
          console.log(budgetItemId + " " + totalSpent);
          budgetItemService
            .addSpent(
              this.currentOrganisation.id,
              this.currentCase.id,
              budgetItemId,
              this.util.toFixed(totalSpent,2)
            )
            .then((result) => {
              console.log("spent added", result);
            })
            .catch((err) => {
              console.log(err);
            });

          this.notes = notes.sort((ax, bx) => {
            if (ax.startDate == undefined) {
              return -1;
            }
            if (bx.startDate == undefined) {
              return 1;
            }
            let a = ax.startDate.toDate();
            let b = bx.startDate.toDate();
            return a > b ? -1 : a < b ? 1 : 0;
          });
        });
        budgetItemService
        .list(this.currentOrganisation.id, this.currentCase.id)
        .then((result) => {
          let budgetItems = [];
          let reportObj={};
          let reportData=[];
          result.docs.map((doc) => {
            const item = doc.data();
            item.id = doc.id;
            budgetItems.push(item);
          });

         
         this.budgetItems=budgetItems;

         console.log(this.budgetItems);

          if (this.budgetItems.length != 0) {
                for (let p = 0; p < this.budgetItems.length; p++) {
                  console.log(this.budgetItems[p].length);
                  reportObj["budget"] = this.budgetItems[p].budget;
                  reportObj["budgetItem"] = this.budgetItems[p].title;
                  reportObj["budgetItemId"] = this.budgetItems[p].id;
                  reportObj["budgetItemLongName"] =
                    this.currentCase.participantDetails.firstname +
                    " " +
                    this.currentCase.participantDetails.lastname +
                    " " +
                    "-" +
                    this.budgetItems[p].title;
                  reportObj["firstname"] = this.currentCase.participantDetails.firstname;
                  reportObj["lastname"] = this.currentCase.participantDetails.lastname;
                  reportObj["organisation"] = this.currentOrganisation.name;
                  reportObj["participantId"] = this.currentCase.id;
                  if (this.budgetItems[p].spent !== undefined) {
                    reportObj["totalSpent"] = this.budgetItems[p].spent;
                  } else {
                    reportObj["totalSpent"] = 0;
                  }

                  reportData.push(reportObj);
                  reportObj = {};
                }
                console.log(reportData);
              }
             // this.deleteReport(reportData);
             // this.addReport(reportData);
                     
        });
      
    },

    moment: function (date) {
      return date != undefined ? moment(date) : moment();
    },
    toggleDeleted() {
      //this.deleted = !this.deleted;
      if (this.deleted) {
        this.getDeleted();
      }
    },
    getDeleted() {
      notesService
        .listRemoved(
          this.currentOrganisation.id,
          this.currentCase.id,
          this.currentGoal.id
        )
        .then((result) => {
          let notes = [];
          result.docs.map((doc) => {
            const note = doc.data();
            note.id = doc.id;
            notes.push(note);
          });

          this.deletedNotes = notes.sort((ax, bx) => {
            if (ax.modifiedOn == undefined) {
              return 1;
            }
            if (bx.modifiedOn == undefined) {
              return -1;
            }
            let a = ax.modifiedOn.toDate();
            let b = bx.modifiedOn.toDate();
            return a > b ? -1 : a < b ? 1 : 0;
          });
        });
    },
    deleteItem(item) {
      const me = this;
      const index = me.notes.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        notesService
          .remove(
            me.currentOrganisation.id,
            me.currentCase.id,
            me.currentGoal.id,
            item.id,
            me.user
          )
          .then((result) => {
            console.log(result);
            me.notes.splice(index, 1);
          });
      }
    },
    unDeleteItem(item) {
      const me = this;
      const index = me.deletedNotes.indexOf(item);

      notesService
        .unRemove(
          me.currentOrganisation.id,
          me.currentCase.id,
          me.currentGoal.id,
          item.id
        )
        .then((result) => {
          console.log(result);
          me.notes.push(item);
          me.deletedNotes.splice(index, 1);
        });
    },
    addNote() {
      if (this.currentCase.notes == undefined) {
        this.currentCase.notes = [];
      }

      this.editedIndex = -1;
      this.caseId = this.currentCase.id;
      this.goalId = this.currentGoal.id;
      this.participantName = this.participantName;

      let dt = new Date();
      dt.setHours(0);
      dt.setMinutes(0);
      dt.setSeconds(0);
      let mdt = moment(dt);
      this.$store.commit("setCurrentProgressNote", {
        endDateString: mdt.format("YYYY-MM-DD"),
        startDateString: mdt.format("YYYY-MM-DD"),
        startTime: mdt.format("HH:mm"),
        endTime: mdt.format("HH:mm"),
      });
      this.$router.push("/progress-notes");
    },
    noteAdded(data) {
      if (this.editedIndex > -1) {
        Object.assign(this.notes[this.editedIndex], data);
      } else {
        this.notes.push(data);
        this.$forceUpdate();
      }
      this.noteClosed();
      console.log(data);
    },
    noteClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.currentCase.contacts = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4",
          });
        }
      });
    },
    editItem(item) {
      this.editedIndex = this.notes.indexOf(item);
      this.caseId = item.caseId;
      this.goalId = item.goalId;
      this.participantName = item.participantName;
      this.nDISNumber = item.nDISNumber;

      this.vm = Object.assign({}, item);
      this.$store.commit("setCurrentProgressNote", item);
      this.$router.push("/progress-notes");
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

     deleteReport(reportData) {
      reportService1
        .getBudgetItemsTotalsReport1(this.currentOrganisation.id)
        .then((doc) => {
          const rows = doc.data().rows;

          for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < reportData.length; j++) {
              if (rows[i].budgetItemId == reportData[j].budgetItemId) {
                if (
                  rows[i].budget != reportData[j].budget &&
                  rows[i].totalSpent == reportData[j].totalSpent
                ) {
                  console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
                if (
                  rows[i].budget == reportData[j].budget &&
                  rows[i].totalSpent != reportData[j].totalSpent
                ) {
                  //console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
                if (
                  rows[i].budget != reportData[j].budget &&
                  rows[i].totalSpent != reportData[j].totalSpent
                ) {
                  console.log(rows[i]);
                  reportService1
                    .deleteReport(this.currentOrganisation.id, rows[i])
                    .then((result) => {
                      console.log("report deleted", result);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
            }
          }
        });
    },
    addReport(reportData) {
      for (let x = 0; x < reportData.length; x++) {
        reportService1
          .addReport(this.currentOrganisation.id, reportData[x])
          .then((result) => {
            console.log("report added", result);
          })
          .catch((err) => {
            console.log(reportData[x]);
            console.log(err);
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.truncate {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
